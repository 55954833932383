<template>
  <div v-loading="loading">
    <TaskLayout
      :title="$t('tasks.uncertainBillingTask.title')"
      :task="task"
      :task-closeable="canTaskBeClosed"
      :custom-close-task-text="$t('tasks.uncertainBillingTask.closeTaskText')"
      @handle-mark-complete="handleMarkComplete"
    >
      <template #task-snooze-modal-subtitle>
        <RestaurantSupplierSubtitle
          :supplier-name="supplier.name"
          :customer-name="currentTenant.name"
          @handle-mark-complete="handleMarkComplete"
        />
      </template>
      <template #actions>
        <RequestsButtonContainer
          v-if="task && task.data"
          :source-business-id="task.businessId"
          :task-id="taskId"
          :target-businesses="[task.data.supplierId, task.businessId]"
        />
      </template>
      <template #details>
        <div class="details-container p-6">
          <div class="d-flex gap-1 align-items-center">
            <span class="subtitle-text">{{ currentTenant.name }}</span>
            <ArrowLeftIcon class="subtitle-icon" :fill="'#46494f'" />
            <span class="subtitle-text">{{ supplier.name }}</span>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.reconciliationInfo') }}</div>
            <Button
              type="link"
              class="text-typography-primary p-0 text-decoration-underline"
              @click="handleReconciliationDateClick"
            >
              {{ $t('tasks.details.reconciliation', { date: reconciliationDate }) }}
            </Button>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.reason') }}</div>
            <div>
              {{ uncertainBilling.reason ? $t(`tasks.reasons.${uncertainBilling.reason}`) : '-' }}
            </div>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.comments') }}</div>
            <div>{{ taskComment }}</div>
          </div>
          <div class="details-row mt-4">
            <div class="detail">{{ $t('tasks.details.reconciliationSettings') }}</div>
            <div class="d-flex gap-2">
              <div v-if="reconciliationTemplate.note">
                <div v-if="reconciliationTemplate.note.length < 80">{{ reconciliationTemplate.note }}</div>
                <div v-else style="width: 552px">
                  <TruncatedText>{{ reconciliationTemplate.note }}</TruncatedText>
                </div>
              </div>
              <Button
                type="link"
                class="text-typography-primary p-0 text-decoration-underline"
                @click="openReconciliationTemplateModal"
                >{{ $t('tasks.details.showSettings') }}
              </Button>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div class="w-100 d-flex gap-7 content-container">
          <div v-if="document" class="content-child">
            <div class="content-headline-text">
              {{ $t(`document.exports.schema.type.shortName.${document.type}`) }}
              {{ document.documentNumber }}
            </div>
            <div class="document-details mt-5 px-4 d-flex align-items-center">
              <div>
                <span>{{ $t('commons.reference') }}:</span
                ><span class="text-underline-hover text-primary" @click="handleDocumentRefClick(document.id)">
                  {{ $t(`document.exports.schema.type.shortName.${document.type}`) }}
                  {{ document.documentNumber }}
                </span>
              </div>
              <div>
                <span>{{ $t('commons.date') }}:</span>
                <span>{{ formatDateNumbers(document.issueDate || document.deliveryDate) }}</span>
              </div>
              <div>
                <span>{{ $t('commons.total') }}:</span> <span>{{ formatMoney(uncertainBilling.totalAmount) }}</span>
              </div>
            </div>
            <div class="document-viewer mt-3">
              <FileViewer v-if="document" :url="document.filePathUrl" />
            </div>
          </div>
          <div class="content-child">
            <div class="content-headline-text">
              {{ $t('tasks.uncertainBillingTask.decisionMaking') }}
            </div>
            <el-radio-group v-model="decision" class="radio-group d-flex flex-column">
              <el-radio :label="DECISION.CREATE_NEW_BILLING"
                >{{ $t('tasks.uncertainBillingTask.createNewBillingEvent') }}
              </el-radio>
              <el-radio :label="DECISION.MERGE_WITH_EXISTING_BILLING" class="mt-2"
                >{{ $t('tasks.uncertainBillingTask.mergeWithExistingEvent') }}
              </el-radio>
            </el-radio-group>
            <div class="billing-events-wrapper">
              <div
                class="d-flex justify-content-between align-items-center"
                :class="!decisionEnabled ? 'text-disabled' : ''"
              >
                <span class="subtitle-text">{{
                  $t('tasks.uncertainBillingTask.eventsOf', { date: formatDateLong(currentBillingMonth) })
                }}</span>
                <div class="d-flex">
                  <div class="chevron" role="button" @click="billingMonthMinus">
                    <ChevronIcon :fill="decisionArrowColor" :direction="$direction === 'rtl' ? 'right' : 'left'" />
                  </div>
                  <span class="d-flex align-items-center">{{ formatDateShort(currentBillingMonth) }}</span>
                  <div class="chevron" role="button" @click="billingMonthPlus">
                    <ChevronIcon :fill="decisionArrowColor" :direction="$direction === 'rtl' ? 'left' : 'right'" />
                  </div>
                </div>
              </div>
              <BillingTable
                v-loading="billingsLoading"
                class="mt-4"
                :table-disabled="!decisionEnabled"
                :billings="billings"
                :selected-index="billings.indexOf(selectedBilling)"
                @row-click="selectedBilling = $event"
                @document-click="handleDocumentRefClick"
              />
            </div>
          </div>
        </div>
      </template>
    </TaskLayout>

    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="openChat"
    />

    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="currentTenant"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />

    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
  </div>
</template>

<script>
import { isNil, pluck } from 'ramda';
import { computed, onBeforeUnmount, ref, getCurrentInstance, watch } from 'vue';
import { DateTime } from 'luxon';

import { ArrowLeftIcon, ChevronIcon } from '@/assets/icons';
import { Button, TruncatedText } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useSupplier } from '@/modules/suppliers';
import { useChatModal } from '@/modules/chatModal';
import { useDocumentNew2 } from '@/modules/document/compositions';
import { DocumentModal } from '@/modules/documentModal';
import { options } from '@/locale/dateConfig';
import {
  ReconciliationModal,
  useReconciliationTemplate,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  ReconciliationSettingsModal,
} from '@/modules/reconciliation';
import { FileViewer } from '@clarityo/ui-components';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useBillingsWithNavigation } from '@/modules/billing';

import TaskLayout from './components/TaskLayout';
import BillingTable from './components/BillingTable';
import { useLastReconciliation, useResolveUncertainBilling, useUncertainBilling } from './composition';
import { RestaurantSupplierSubtitle } from '../components';
import { useTask, useActivityTrack } from '../compositions';
import { RequestsButtonContainer } from './components';

const DECISION = {
  NONE: 'none',
  CREATE_NEW_BILLING: 'createNewBilling',
  MERGE_WITH_EXISTING_BILLING: 'mergeWithExistingBilling',
};

const decisionArrowColor = '#BBBEC2';

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    TaskLayout,
    ReconciliationModal,
    ArrowLeftIcon,
    ReconciliationSettingsModal,
    Button,
    RestaurantSupplierSubtitle,
    TruncatedText,
    DocumentModal,
    FileViewer,
    ChevronIcon,
    BillingTable,
    RequestsButtonContainer,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const taskId = ref(root.$route.params.taskId);

    const { task, loading: taskLoading } = useTask(() => ({
      id: taskId.value,
    }));

    const { uncertainBilling, loading: uncertainBillingLoading } = useUncertainBilling(
      computed(() => ({ id: task.value?.data?.uncertainBillingId }))
    );

    const { currentTenant, currentTenantId } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();

    const { reconciliation, loading: reconciliationLoading } = useLastReconciliation(
      computed(() => ({
        businessId: currentTenant.value.id,
        supplierId: task.value?.data?.supplierId,
      }))
    );

    watch(task, (newTask) => {
      if (newTask.businessId !== currentTenantId.value) {
        root.$router.push({ name: 'uncertain-billing-tasks' });
      }
    });

    useActivityTrack(taskId);
    const { supplier } = useSupplier(computed(() => task.value?.data?.supplierId));

    const layoutLoading = ref(false);

    const {
      reconciliationTemplate,
      loading: reconciliationTemplateLoading,
      refetch: reconciliationTemplateRefetch,
    } = useReconciliationTemplate(
      computed(() => ({
        businessId: currentTenant.value.id,
        supplierId: task.value?.data?.supplierId,
      }))
    );

    const { document, loading: documentLoading } = useDocumentNew2(computed(() => task.value?.data?.ref));

    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();
    const { mutate: resolveUncertainBilling } = useResolveUncertainBilling();

    const { closeChatModal, openChatModal, isChatOpen } = useChatModal();

    const currentBillingMonth = ref(DateTime.local().toISODate());
    const businessId = computed(() => currentTenant.value.id);
    const supplierId = computed(() => supplier.value.id);
    const {
      billings,
      loading: billingsLoading,
      range: billingsRange,
      goForward: goForwardBillings,
      goBackward: goBackwardBillings,
    } = useBillingsWithNavigation(
      businessId,
      supplierId,
      DateTime.fromISO(currentBillingMonth.value).toFormat('yyyy-MM')
    );

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    return {
      currentTenant,
      formatCentsToCurrency,
      supplier,
      task,
      reconciliation,
      document,
      layoutLoading,
      loading: computed(
        () =>
          taskLoading.value ||
          reconciliationLoading.value ||
          layoutLoading.value ||
          documentLoading.value ||
          uncertainBillingLoading.value
      ),
      pickedReconciliationId: ref(null),
      reconciliationTemplate,
      reconciliationTemplateParams: ref(null),
      isSupplierTemplateDefined: computed(() => !!reconciliationTemplate.value?.supplierId),
      createReconciliationTemplate,
      updateReconciliationTemplate,
      reconciliationTemplateLoading,
      reconciliationTemplateRefetch,
      closeChatModal,
      openChatModal,
      isChatOpen,
      DECISION,
      displayedDocumentId: ref(null),
      decision: ref(DECISION.NONE),
      billings,
      billingsLoading,
      billingsRange,
      goForwardBillings,
      goBackwardBillings,
      currentBillingMonth,
      selectedBilling: ref(null),
      taskId,
      resolveUncertainBilling,
      uncertainBilling,
      decisionArrowColor,
    };
  },

  computed: {
    taskComment() {
      return this.task.data?.comment ?? '';
    },
    reconciliationDate() {
      const { periodStart, periodEnd } = this.reconciliation;
      if (!periodStart) return '';
      if (periodStart === periodEnd)
        return new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits);

      return this.formatDateShort(DateTime.fromISO(this.reconciliation.periodEnd).startOf('month'));
    },
    reconciliationTemplatedDefinedText() {
      return this.isSupplierTemplateDefined ? this.$t('tasks.supplierTask.content.defined') : '-';
    },
    canTaskBeClosed() {
      return (
        this.decision === this.DECISION.CREATE_NEW_BILLING ||
        (this.decision === this.DECISION.MERGE_WITH_EXISTING_BILLING && !isNil(this.selectedBilling))
      );
    },
    decisionEnabled() {
      return this.decision === this.DECISION.MERGE_WITH_EXISTING_BILLING;
    },
  },
  watch: {
    $route() {
      const { taskId: newTaskId } = this.$route.params;
      this.taskId = newTaskId;
      this.decision = this.DECISION.NONE;
    },
  },
  methods: {
    hasRegulatory(billing) {
      return pluck('regulatory', billing.eventReferences).find((regulatory) => regulatory);
    },
    async handleMarkComplete(completeFunc) {
      const amountsNotMatch =
        this.decision === DECISION.MERGE_WITH_EXISTING_BILLING &&
        this.uncertainBilling.totalAmount !== this.selectedBilling.totalAmount;

      const multipleRegulatory =
        this.decision === DECISION.MERGE_WITH_EXISTING_BILLING &&
        this.hasRegulatory(this.selectedBilling) &&
        this.hasRegulatory(this.uncertainBilling);

      if (amountsNotMatch) {
        this.$message.error(this.$t('tasks.uncertainBillingTask.billingAmountsDoNotMatch'));
      } else if (multipleRegulatory) {
        this.$message.error(this.$t('tasks.uncertainBillingTask.cannotMergeTwoRegulatoryDocs'));
      } else {
        const billingId = this.decision === DECISION.MERGE_WITH_EXISTING_BILLING ? this.selectedBilling.id : null;
        await this.resolveUncertainBilling({ uncertainId: this.task.data.uncertainBillingId, billingId });
        await sleep(2000);
        await completeFunc();
      }
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    handleReconciliationDateClick() {
      this.pickedReconciliationId = this.reconciliation.id;
    },
    formatDateShort(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: 'numeric',
          })
        : '';
    },
    formatDateLong(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'long',
            year: 'numeric',
          })
        : '';
    },
    formatDateNumbers(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
      });
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    openReconciliationTemplateModal() {
      this.reconciliationTemplateParams = {
        supplierId: this.supplier.id,
        template: this.reconciliationTemplate,
      };
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },
    openChat() {
      const { periodStart, periodEnd } = this.reconciliation;
      const formattedPeriod =
        periodStart === periodEnd
          ? new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits)
          : DateTime.fromISO(periodStart).toLocaleString({
              month: 'short',
              year: 'numeric',
            });
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier: this.supplier,
        title,
        formattedPeriod,
        reconciliationId: this.reconciliation.id,
        isChannelEnabled: !this.reconciliation.closed,
      });
    },
    handleDocumentRefClick(id) {
      this.displayedDocumentId = id;
    },
    billingMonthMinus() {
      if (this.decisionEnabled) {
        this.goBackwardBillings();
        this.currentBillingMonth = DateTime.fromISO(this.currentBillingMonth).minus({ months: 1 }).toISO();
        this.selectedBilling = null;
      }
    },
    billingMonthPlus() {
      if (this.decisionEnabled) {
        this.goForwardBillings();
        this.currentBillingMonth = DateTime.fromISO(this.currentBillingMonth).plus({ months: 1 }).toISO();
        this.selectedBilling = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.details-container {
  border: 1px solid #d9dcde;
  border-radius: 4px;
  margin-top: 48px;
}

.radio-group {
  margin-top: 30px;
}

.billing-events-wrapper {
  margin-top: 28px;
}

.details-row {
  display: flex;
  .detail {
    width: 160px;
    min-width: 160px;
    color: $typography-secondary;
  }
}

.subtitle-icon {
  width: 16px;
  height: 16px;
}

.subtitle-text {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
}

.content-headline-text {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1rem;
}

.document-details {
  width: 100%;
  height: 52px;
  background: #f8fafb;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  > div:not(:first-child) {
    margin-right: 32px;
  }
}

.text-underline-hover {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.content-child {
  flex: 1 1 0px;
}

.document-viewer {
  height: 600px;
}

.content-container {
  min-height: 680px;
  margin-top: 48px;
}
</style>
